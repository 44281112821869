import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Search() {
  const [input, setInput] = useState('');
  const navigate = useNavigate();

  const handleChange = (event) => {
    setInput(event.target.value);
  };

  const handleNavigate = () => {
    navigate(`/buscar/${input.replaceAll(' ', '-')}`);
  };

  return (
    <div className="w-100 bg-black/50">
      <button className="p-2 float-right">
        <FontAwesomeIcon
          icon={faTimes}
          className="text-3xl text-[#e7e7e7] hover:text-red-700 transition ease-in duration-75"
        />
      </button>
      <div className="flex justify-center m-auto top-16 relative outline outline-black w-1/2 bg-black">
        <input
          type="text"
          className="bg-transparent w-100 p-4 placeholder-[#e7e7e7]/50 text-white text-xl font-bold outline-none"
          placeholder="Escriba su búsqueda"
          name="search"
          autoFocus
          value={input}
          onChange={handleChange}
        />
        <button onClick={handleNavigate} className="p-4">
          <FontAwesomeIcon
            icon={faSearch}
            className="text-3xl text-[#e7e7e7]/50 hover:text-white transition ease-in duration-75"
          />
        </button>
      </div>
    </div>
  );
}

export default Search;
