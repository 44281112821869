
// Styles
import './App.css';
import { useEffect, useState } from "react";
import HeaderTest from './components/Home/HeaderTest.jsx';
import GitCarousel from './components/Home/GitCarousel.jsx';
import Footer from './components/Footer/Footer';
import PostsComponent from './components/Home/PostsComponent';
import Widget from "./components/Home/Widget.jsx";
import SectionItem from './components/Home/SectionItem.jsx';
import Policiales from "./components/Containers/Policiales.jsx";
import Deportes from "./components/Containers/Deportes.jsx";
import Tecnologia from "./components/Containers/Tecnologia.jsx";

import axios from "axios";
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import BannerAd from "./components/Ads/BannerAd.jsx";
import BannerAdDos from "./components/Ads/BannerAdDos.jsx";
import loteo from './assets/banners/loteo.gif'

  /*Imagenes de Publicidades*/
  import Banner from './assets/data/banner.gif';
  import Publicidades from './components/Ads/Publicidades.jsx';
  import newBpn2 from './assets/data/bpn.jpg';

export default function App () {

  const [cortes, setCortes] = useState([]);

  /* <===== Scroll to TOP =====> */
  const [showButton, setShowButton] = useState(false);
  
  useEffect( () => {

    const getCortes = async () => {
      const result = await axios.get(process.env.REACT_APP_CORTES_API_URL);
      setCortes(result.data)
    }

    window.addEventListener("scroll", () => { if(window.pageYOffset > 300) { setShowButton(true); } else { setShowButton(false); } })

    getCortes();

  },[]);

  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

 return(
  <>

  {/* <Helmet>
    <meta charSet='utf-8' />
    <title>VoxDiario | Portal de Noticias</title>
    <link rel="canonical" href="https://voxdiario.com"/>
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://voxdiario.com/" />
    <meta property="og:title" content="VoxDiario | Portal de Noticias" />
    <meta property="og:description" content="Noticias de Neuquén, Río Negro, la Patagonia, Argentina y el mundo en tiempo real. Enteráte antes que nadie con el portal de noticias digital, VoxDiario.
        Los 40 Neuquén FM 92.3" />
    <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
  </Helmet> */}

  {/* <Provider> */}
    {/*===================  Header && Nav  ==================*/}
    <HeaderTest/>
    {/* <Header/> */}
    {/*===================  Advertisement ===================*/}
      
    {/*======================  Section 1 =====================*/}
      
      {/*======================  Carousel =====================*/}
    <div id="container">
      <Publicidades imagen={Banner} />
      <GitCarousel />
    {/*======================  Section 2 =====================*/}
      <PostsComponent cortes={cortes}/>
      <hr className={isMobile ? 'h-10' : ''}/>

      {/*===================  Advertisement ===================*/}
        <BannerAd/>

    {/*=======================  Widget ======================*/}

      <hr className={isDesktop ? 'h-8' : 'mt-10'}/>
        <Widget/>
        <hr className={isDesktop ? 'h-8' : 'mb-10'}/>
    </div>

    {/*======================  Section 4 =====================*/}
      <div id="container">
        <BannerAdDos imagen={loteo}/>
        <hr className={isDesktop ? 'h-8 mt-10' : 'mt-10 container-md'}/>
      </div>
    {/*======================  Section 5 =====================*/}
      <Policiales />
      <br></br>
      <br></br>
      <Deportes />
      <Tecnologia/>

      <div className="w-100 p-2 h-48 outline outline-1 outline-black hover:outline-blue-800 container-md my-4" >
      <a href="http://mutualbpn.com.ar/" target="_blank">
        <img src={newBpn2} className="h-100 w-62 flex justify-center m-auto" alt="Publicidad Skin Factory"/>
      </a>
    </div>
      

    <SectionItem />
    {/*=======================  // ======================*/}

    {/* CATEGORÍAS */}
    <Footer/>


    <button
      type="button"
      className={ showButton ? " inline-block w-10 m-1 h-9 animate-bouncing-button bg-purple-500 text-white font-xs hover:bg-purple-700 hover:shadow-lg focus:bg-purple-600 focus:shadow-lg focus:outline-none  active:bg-purple-800 active:shadow-lg transition duration-75 ease-in-out bottom-24 right-1 fixed"
    : "hidden w-9 h-9 bg-purple-500 text-white font-xs hover:bg-purple-700 hover:shadow-lg focus:bg-purple-600 focus:shadow-lg focus:outline-none  active:bg-purple-800 active:shadow-lg transition duration-75 ease-in-out bottom-4 right-1 fixed"}
      id="goTop"
      onClick={handleScroll}>

      <FontAwesomeIcon icon={faChevronUp} className="text-xs" />

    </button>
  {/* </Provider> */}
  </>
 )
};
