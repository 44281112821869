import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'
import { faPrint, faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet'

function InmobiliariasDetail(props) {

  const { element } = props;
  const navigate = useNavigate()

  let replaced = element.Entry_Title.replaceAll(' ', '-');

  const SHARE_URL = `https://www.voxdiario.com/${replaced}`

  
  const Print = () => {
    let printContents = document.getElementById('printablediv').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    navigate(0)
  }

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  
  return (
    <section className='max-h-max'>
      <Helmet>
        <meta charSet='utf-8'/>
        <title>VoxDiario | {element.Entry_Title}</title>
        <meta charset="utf-8" />
        <link rel="icon" type="image/x-icon" href="%PUBLIC_URL%/favicon.ico"/>
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>    
        <meta name="theme-color" content="#000000"/>
        <meta name="description" content={element.Entry_Resume}/>
        <meta name="author" content="Lautaro Santillán"/>
        <base href="%PUBLIC_URL%/" />
        <link rel="manifest" href="manifest.json"/>
        {/* <!-- FONTS --> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet" />
        <script src="https://unpkg.com/react/umd/react.production.min.js" crossorigin></script>
        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://voxdiario.com/${replaced}`} />
        <meta property="og:title" content={`VoxDiario | ${element.Entry_Title}`} />
        <meta property="og:description" content={element.Entry_Resume} />
        <meta property="og:image" content={element.Entry_Featured_Image} />
        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={`https://www.voxdiario.com/${replaced}`}/>
        <meta property="twitter:title" content={`VoxDiario | ${element.Entry_Title}`} />
        <meta property="twitter:description" content={element.Entry_Resume} />
        <meta property="twitter:image" content={element.Entry_Featured_Image} />
      </Helmet>
      {/* PRINT */}
      <div className='' id="printablediv">
        <h2 className='text-xl md:text-4xl font-bold py-2'>{element.Entry_Title}</h2>
        <h3 className='text-md md:text-xl text-[#777777] py-2'> {element.Entry_Resume}</h3>


        {/* POST INFO WIDGET */}
        <ul className='py-3 gap-2 w-100 hidden'>
          <li>{element.Author_Name}</li>
          <li>{element.createdAt}</li>
          <li>Tiempo de lectura</li>  
        </ul>

        <div className='md:w-2/3'>
          <img src={element.Entry_Featured_Image}/>

          {/* SHARE POST WIDGET */}
          <ul className='flex justify-between md:w-2/3 p-3'>
            <span className='flex justify-start my-auto'>
              <FontAwesomeIcon icon={faShareNodes} className="p-2 text-black"/>
              <p className='py-2 text-xs'>Compartir</p>
            </span>
            <ul className='flex'>
              <li className='p-1'>
                <FacebookShareButton url={SHARE_URL} hashtag='#VoxDiario'>
                  <FacebookIcon className='rounded-full' size={'40px'}/>
                  </FacebookShareButton>
              </li>
              <li className='p-1'>
                <TwitterShareButton url={SHARE_URL}>
                <TwitterIcon className='rounded-full' size={'40px'}/>
                </TwitterShareButton>
              </li>
              <li className='p-1'>
                <WhatsappShareButton url={SHARE_URL} title={element.Entry_Title}>
                <WhatsappIcon size={'40px'} className="rounded-full"/>
                </WhatsappShareButton>
              </li>
              <li>
                <button className='p-2 w-[40px] rounded-full m-1 bg-neutral-700 text-white hover:bg-neutral-600'
                  onClick={Print}>
                  <FontAwesomeIcon icon={faPrint}/>
                </button>
              </li>
            </ul>
          </ul>
          <article className='w-100'>
            <p className='' 
            dangerouslySetInnerHTML={ { __html: element.Entry_Body}}></p>
            <p>{element.Tags}</p>
          </article>

        </div>
      </div>

      <div>
        <ul className='flex w-2/3 p-3'>
          <span className='flex justify-start my-auto'>
            <FontAwesomeIcon icon={faShareNodes} className="p-2 text-black"/>
            <p className='py-2 text-xs'>Compartir</p>
          </span>

          <span className='flex ml-10'>
            <ul className='flex'>
              <li className='p-1'>
                <FacebookShareButton url={SHARE_URL} hashtag='#VoxDiario'>
                <FacebookIcon className='rounded-full' size={'40px'}/>
                </FacebookShareButton>
              </li>
              <li className='p-1'>
                <TwitterShareButton url={SHARE_URL}>
                <TwitterIcon className='rounded-full' size={'40px'}/>
                </TwitterShareButton>
              </li>
              <li className='p-1'>
                <WhatsappShareButton url={SHARE_URL} title={element.Entry_Title}>
                <WhatsappIcon size={'40px'} className="rounded-full"/>
                </WhatsappShareButton>
              </li>
              <li>
                <button className='p-2 w-[40px] rounded-full m-1 bg-neutral-700 text-white hover:bg-neutral-600'
                  onClick={Print}>
                  <FontAwesomeIcon icon={faPrint}/>
                </button>
              </li>
            </ul>
          </span>
        </ul>
      </div>

      <div id="wrapper-category" className='hidden w-[440px] h-12 bg-yellow-600'>
        <h2 className='text-white font-bold py-2 flex justify-center relative left-3 p-2 text-xl'>Publicaciones relacionadas</h2>
      </div>
    </section>
  )
}

export default InmobiliariasDetail