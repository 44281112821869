import { faHome, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMediaQuery } from 'react-responsive';
import Header from "../../components/Home/HeaderTest.jsx";
import axios from "axios";
import Banner from './../../assets/data/banner.gif';
import Publicidades from "../../components/Ads/Publicidades";
import Footer from "../../components/Footer/Footer.jsx";

const Category = () => {
  const { category } = useParams();
  const output = category.replace(/^\w/, c => c.toUpperCase());

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [offset, setOffset] = useState(0); // Offset para cargar más noticias
  const limit = 10; // Límite de noticias a cargar por vez

  const fetchPosts = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_GET_CATEGORIES}/${category}`);
      if (offset === 0) {
        setPosts(response.data.noticias);
        console.log(posts)
      } else {
        setPosts(prevPosts => [...prevPosts, ...response.data.noticias]);
      }
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, [category, offset]);

  const loadMorePosts = () => {
    setOffset(prevOffset => prevOffset + limit);
  };

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  return (
    <>
      <Header category={category} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>VoxDiario | {output}</title>
      </Helmet>

      <section id="container" className="min-h-screen">
        <Publicidades imagen={Banner} />
        <p className="text-sm px-12 text-neutral-400">
          <Link to="/" className="hover:text-purple-700 duration-200"><FontAwesomeIcon icon={faHome} /> Inicio </Link>
          / {output}
        </p>

        <h2 className="text-4xl font-bold my-8 px-12">{output}</h2>

        <section className="w-100 container">
          {loading ? (
            <p>Cargando...</p>
          ) : error ? (
            <p>Ocurrió un error: {error}</p>
          ) : (
            <div>
              {posts.map((post, index) => (
                <div key={index} className={`md:flex ${isDesktop ? '' : 'h-auto'} p-3 mb-4 w-100 shadow rounded-md`}>
                  <div className={isDesktop ? "w-1/2" : "w-100"}>
                    <a href={post.Entry_Category + '/' + post._id} className="">
                      <img className={isDesktop ? "h-80 w-100 aspect-auto" : "filter hover:brightness-105 object-contain max-h-max w-auto saturate-105 delay-100 duration-150"} src={post.Entry_Featured_Image} alt={post.Entry_Title} />
                    </a>
                  </div>

                  <div className={isDesktop ? 'my-8 w-2/3' : "w-full"}>
                    <span className={isDesktop ? "flex relative px-3" : "flex p-1"}>
                      <FontAwesomeIcon className={isDesktop ? 'text-xs pt-0.5 text-neutral-400 font-light' : 'text-neutral-700 text-xs mt-0.5'} icon={faClock} /> <p className="px-1 text-xs text-neutral-400 font-light">{post.createdAt.slice(0, 10).replaceAll('-', '/')}</p>
                    </span>
                    <span>
                      <a href={post.Entry_Category + '/' + post._id} className="hover:text-purple-600 duration-200">
                        <h2 className={`text-md ${isDesktop ? 'md-text-2xl px-3' : 'p-1'} w-auto font-bold font-sans`}>{post.Entry_Title}</h2>
                      </a>
                      <p className={isDesktop ? "px-3" : "p-1 text-sm"} id="card__preview-text">{post.Entry_Resume}</p>
                      <a href={post.Entry_Category + '/' + post._id} className={isDesktop ? "block mt-2 px-3" : ""}>
                        <button className='p-2 text-white text-sm bg-[#604594]'>Leer más »</button>
                      </a>
                    </span>
                  </div>
                </div>
              ))}
              <button onClick={loadMorePosts}>Cargar más noticias</button>
            </div>
          )}
        </section>
      </section>
      <Footer />
    </>
  );
};

export default Category;
