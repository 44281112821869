import { createRoot, reactDOM } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
const container = document.getElementById('root');
const root = createRoot(container);

import 'bootstrap/dist/css/bootstrap.min.css';


import App from './App';
/*<----- Public Routes ----->*/
import Login from './components/Home/Login.jsx';
import Category from './Pages/Categories/Category';
import PostContainer from './Pages/PostContainer/PostContainer';
/*<----- Admin Routes ----->*/
import Dashboard from './components/Dashboard/Dashboard';
import Ui from './Pages/Admin/Ui';
import Post from './Pages/Admin/Posts';
import EditPostContainer from './components/Admin/EditPostContainer';
// import Search from './Pages/Search'
import SearchPage from './components/Search/SearchPage';
import Inmobiliarias from './Pages/Inmobiliarias/Inmobiliarias';
import InmobiliariasContainer from './Pages/Inmobiliarias/InmobiliariasContainer';
import ScrollToTop from './Helpers/ScrollToTop.jsx';
import Weather from './components/Weather/Weather.jsx';


root.render(
  <BrowserRouter>
      <ScrollToTop />
    <Routes>
      {/* PUBLIC ROUTES */}
      <Route path="/" element={<App/>}></Route>
      <Route path="/search/:category" element={<Category/>}></Route>
      <Route path="/buscar/:postCategory" element={<SearchPage/>}></Route>
      <Route path="/Inmobiliarias" element={<Inmobiliarias/>}></Route>
      <Route path="/Inmobiliarias/:id" element={<InmobiliariasContainer/>}></Route>

      {/* USER ROUTES */}
      <Route path="/account/login" element={<Login/>}></Route>
      <Route path="/admin" element={<Ui/>}></Route>
      <Route path="/admin/edit/:post" element={<EditPostContainer/>}></Route>
      <Route path="/Publicaciones/Nueva/Crear" element={<Post/>}></Route>

      {/* CONTENT ROUTES */}
      <Route path="/Dashboard" element={<Dashboard/>}></Route>

      {/* POST ROUTE */}
      <Route path="/:category/:id" element={<PostContainer/>}></Route>
      <Route path="/Weather" element={<Weather/>}></Route>

    </Routes>
  </BrowserRouter>
);
